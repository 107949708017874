.header {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    background-color: $white;
    position: fixed;
    z-index: 5;
    left: 0;
    right: 0;

    &__wrap {
        position: relative;
        padding: 20px 0;
    }

    &__heading {
        position: relative;
        text-align: center;

        img {
            width: 125px;
            height: auto;
        }
    }

    &__menu {
        width: 20px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 25px;
        z-index: 10;

        span {
            position: absolute;
            display: block;
            background-color: $mainColor;
            height: 2px;
            border-radius: 4px;
            width: 100%;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(1) {
                top: 50%;
                transform: translateY(-50%);
            }

            &:nth-child(3) {
                bottom: 0;
            }
        }
    }

    &__categories {
        display: none;
    }

    &__drawer {
        display: none;
        background-color: $white;
        position: fixed;
        top: 0;
        left: 0;
        right: 20%;
        bottom: -10%;
        z-index: 20;
        transform: translateX(-100%);
        transition-duration: 500ms;
        transition-property: transform;
        overflow-y: scroll;

        &--active {
            transform: translateX(0);
        }

        &__logo {
            text-align: center;
            margin: 30px auto 0;
            position: relative;
            width: 100px;

            img {
                width: 100px;
                height: auto;
            }
        }

        &__categories {
            ul {
                list-style: none;
                margin: 30px 0;
                padding: 0;
                text-align: center;
            }

            li {
                margin: 20px 0;
            }
        }

        &__link {
            color: $mainColor;
            text-decoration: none;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            position: relative;

            &--active {
                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    background-color: $mainRed;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -15px;
                    border-radius: 50%;
                }
            }

            &--balance {
                position: relative;
                left: 15px;

                span {
                    font-weight: 500;
                }

                &::before {
                    content: url('../img/coin.svg');
                    position: absolute;
                    top: 50%;
                    left: -30px;
                    width: 20px;
                    height: 20px;
                    transform: translateY(-50%);
                }
            }
        }

        &__btn {
            margin: 0 auto;
            border: 1px solid $mainRed;
            color: $mainRed;
            text-decoration: none;
            padding: 8px 0;
            max-width: 200px;
            display: block;
            border-radius: 25px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);

            &--active {
                color: $white;
                background-color: $mainRed;
            }
        }
    }
}

@media (min-width: 576px) {
    .header {
        &__drawer {
            right: 50%;
        }
    }
}

@media (min-width: 768px) {
    .header {
        &__drawer {
            right: 65%;
        }
    }
}

@media (min-width: 1024px) {
    .header {
        &__wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding-left: 25px;
            padding-right: 25px;
        }

        &__menu {
            display: none;
        }

        &__categories {
            display: block;

            ul {
                list-style: none;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin: 0;
                padding: 0;
            }
        }

        &__link {
            color: $mainColor;
            margin: 0 10px;
            text-decoration: none;
            font-weight: 400;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            position: relative;

            &::after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                background-color: $mainRed;
                bottom: -10px;
                left: 0;
                transform: scale(0);
                transition-property: transform;
                transition-duration: 500ms;
            }

            &:hover::after {
                transform: scale(1);
            }

            &--active {
                &::after {
                    transform: scale(1);
                }
            }

            &--balance {
                position: relative;

                span {
                    font-weight: 500;
                }

                &::before {
                    content: url('../img/coin.svg');
                    position: absolute;
                    top: 50%;
                    left: -30px;
                    width: 20px;
                    height: 20px;
                    transform: translateY(-50%);
                }
            }
        }

        &__btn {
            margin: 0 8px;
            color: $mainRed;
            border: 1px solid $mainRed;
            padding: 10px 20px;
            border-radius: 25px;
            text-decoration: none;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);

            &::after {
                display: none;
            }

            &:hover {
                color: $white;
                background-color: $mainRed;
                transition-property: all;
                transition-duration: 500ms;
            }

            &--active {
                color: $white;
                background-color: $mainRed;
            }
        }
    }
}