.service {
    &__wrap {
        padding-top: 110px;
        padding-bottom: 50px;
    }

    &__title {
        h1 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            margin: 0 auto;
            font-weight: 700;
            color: $mainColor;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    &__subtitle {
        text-align: center;
        max-width: 600px;
        margin: 0 auto 20px;

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            color: $mainColor;
            margin: 0;
        }

        span {
            font-weight: 500;
        }
    }

    &__form {
        width: 100%;
        max-width: 620px;
        margin: 0 auto;
    }

    &__field {
        select {
            margin-top: 10px;
            width: 100%;
            padding: 7px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $mainColor;
            border-radius: 5px;
            border: 1px solid lighten($mainColor, 60%);
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }

        span {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
            color: $mainColor;
            position: relative;
            top: 10px;
            left: 5px;

            b {
                font-weight: 500;
            }
        }
    }

    &__messages {
        width: 100%;
        max-width: 580px;
        margin: 50px auto 0;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: lighten($mainColor, 50%);
            bottom: -20px;
        }

        p {
            margin: 0 0 15px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            color: $mainColor;
        }
    }

    &__bottom {
        margin-top: 40px;
        width: 100%;
    }

    &__total {
        margin-right: 20px;

        p {
            text-align: right;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $mainColor;
            margin: 0;
            font-weight: 500;
        }
    }

    &__btn {
        margin: 30px auto 0;
        width: 180px;

        button {
            border-radius: 25px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            background-color: $mainRed;
            color: $white;
            padding: 12px 0;
            width: 100%;
            border: none;
            transition-property: background-color;
            transition-duration: 500ms;
            cursor: pointer;

            &:hover {
                background-color: lighten($mainRed, 10%);
            }

            &:focus {
                outline: none;
            }
        }

        &--disabled {
            button {
                background-color: $mainGrey;
                pointer-events: none;
            }
        }
    }
}

@media (min-width: 576px) {
    .service {
        &__form {
            form {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
            }
        }

        &__field {
            flex-basis: 46%;
            margin: 0 10px;
        }
    }
}