.payment {
  &__wrap {
    padding-top: 110px;
    padding-bottom: 50px;
  }

  &__title {
    font-size: px-to-rem(22px);
    line-height: px-to-rem(33px);
    margin: 0 auto;
    font-weight: 700;
    color: $mainColor;
    margin-bottom: 20px;
    text-align: center;
  }

  &__info {
    p {
      text-align: center;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(21px);
      color: $mainColor;
      margin: 0;
      margin-bottom: 10px;

      span {
        font-weight: 500;
      }
    }
  }

  &__form {
    max-width: 500px;
    margin: 20px auto 0;

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
    }

    &__radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;

      label {
        margin-left: 15px;
        cursor: pointer;

        span {
          display: block;
          font-size: px-to-rem(14px);
          line-height: px-to-rem(21px);
          color: $mainColor;
          text-align: center;
          font-weight: 500;
          margin-top: 10px;
          max-width: 200px;
        }
      }

      img {
        width: 200px;
        height: auto;
      }
    }

    &__info {
      margin-top: 30px;

      p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        text-align: justify;
        color: $mainColor;
        margin: 0;
        margin-bottom: 10px;

        span {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }

    &__checkbox {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      input {
        margin-top: 3px;
        flex-basis: 2%;
        cursor: pointer;
      }

      label {
        flex-basis: 94%;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        color: $mainColor;
        text-align: justify;
        font-weight: normal;
        cursor: pointer;
      }
    }

    &__btn {
      margin: 30px auto 0;
      width: 250px;

      button {
        border-radius: 25px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        background-color: $mainRed;
        color: $white;
        padding: 12px 0;
        width: 100%;
        border: none;
        transition-property: background-color;
        transition-duration: 500ms;
        cursor: pointer;

        &:hover {
          background-color: lighten($mainRed, 10%);
        }

        &:focus {
          outline: none;
        }
      }

      &--disabled {
        button {
          background-color: $mainGrey;
          pointer-events: none;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .payment {
    &__form {
      &__buttons {
        flex-direction: row;
        justify-content: center;
      }

      &__radio {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}
