.jumbotron {
    &__wrap {
        padding: 110px 25px 30px;
        display: flex;
        flex-direction: column;
    }

    &__title {
        h1 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            margin: 0 auto;
            text-align: center;
            font-weight: 700;
            color: $mainColor;
            max-width: 550px;
        }
    }

    &__text {
        margin-top: 20px;

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 400;
            text-align: center;
            margin: 0 auto;
            max-width: 550px;
        }
    }

    &__btn {
        margin-top: 25px;

        a {
            border-radius: 25px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            background-color: $mainRed;
            color: $white;
            text-decoration: none;
            padding: 12px 0;
            display: block;
            width: 182px;
            text-align: center;
            margin: 0 auto;
            transition-property: background-color;
            transition-duration: 500ms;

            &:hover {
                background-color: lighten($mainRed, 10%);
            }
        }
    }

    &__img {
        margin-top: 25px;
        text-align: center;

        img {
            width: 100%;
            max-width: 600px;
        }
    }
}

@media (min-width: 1024px) {
    .jumbotron {
        &__wrap {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__left {
            margin-top: 100px;
            flex-basis: 35%;
        }

        &__title {
            h1 {
                text-align: left;
                font-size: px-to-rem(30px);
                line-height: px-to-rem(45px);
            }
        }

        &__text {
            p {
                text-align: left;
                font-size: px-to-rem(16px);
                line-height: px-to-rem(24px);
            }
        }

        &__btn {
            a {
                margin: 0;
                font-size: px-to-rem(16px);
                line-height: px-to-rem(18px);
                width: 200px;
            }
        }

        &__right {
            flex-basis: 60%;
        }
    }
}