.advantages {
    &__wrap {
        padding: 0 25px 10px 25px;
    }

    &__title {
        margin-bottom: 20px;

        h2 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            font-weight: 700;
            color: $mainColor;
            text-align: center;
            margin: 0;
        }
    }

    &__grid {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        background-color: $white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        margin-bottom: 30px;
        width: 260px;
        height: 280px;
    }

    &__img {
        text-align: center;
        margin: 20px 0;

        img {
            width: 94px;
            height: auto;
        }
    }

    &__text {
        padding: 0 30px;

        h3 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            font-weight: 700;
            color: $mainColor;
            text-align: center;
            margin: 0;
        }

        p {
            font-size: px-to-rem(14px);
            line-height: px-to-rem(21px);
            font-weight: 400;
            text-align: center;
            margin: 0;
            margin-top: 20px;
        }
    }
}

@media (min-width: 576px) {
    .advantages {
        &__grid {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__item {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}