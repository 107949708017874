.boards {
    &__wrap {
        padding: 0 25px;
    }

    &__title {
        h3 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            font-weight: 700;
            color: $mainColor;
            text-align: center;
            margin: 0;
        }
    }

    &__grid {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item {
        margin-bottom: 40px;

        img {
            width: 80px;
            height: auto;
        }

        &--probrokery,
        &--brokery365,
        &--brobaza {
            img {
                width: 140px;
                height: auto;
            }
        }
    }
}

@media (min-width: 576px) {
    .boards {
        &__grid {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__item {
            margin-left: 35px;
            margin-right: 35px;
        }
    }
}