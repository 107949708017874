.dashboard {
  &__wrap {
    padding-top: 110px;
    padding-bottom: 50px;
  }

  &__notification {
    max-width: 700px;
    background-color: $addInfoColor;
    border: 1px solid darken($addInfoColor, 20);
    padding: 15px 20px;
    color: $mainColor;
    margin: 25px auto;
    border-radius: 10px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(28px);
      text-align: justify;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &--green {
      background-color: $notificationGreen;
      border: 1px solid $notificationGreenBorder;
    }
  }

  &__title {
    h1 {
      font-size: px-to-rem(22px);
      line-height: px-to-rem(33px);
      margin: 0 auto;
      font-weight: 700;
      color: $mainColor;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__add {
    a {
      border-radius: 25px;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(16px);
      background-color: $mainRed;
      color: $white;
      text-decoration: none;
      padding: 12px 0;
      display: block;
      width: 250px;
      text-align: center;
      margin: 0 auto;
      transition-property: background-color;
      transition-duration: 500ms;

      &:hover {
        background-color: lighten($mainRed, 10%);
      }
    }
  }

  &__ads {
    margin: 30px 0;
  }

  &__text {
    text-align: center;
    margin: 40px 0 10px;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(21px);
  }

  &__item {
    background-color: $white;
    padding: 20px 25px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    max-width: 700px;
    margin: 0 auto 25px;

    &__grid {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      text-align: center;
      margin-bottom: 20px;
      flex-basis: 10%;

      img {
        width: 75px;
        height: auto;
      }
    }

    &__title {
      margin-bottom: 20px;
      flex-basis: 75%;

      p,
      a {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        font-weight: 500;
        text-align: justify;
        color: $mainColor;
        margin: 0;
      }

      a {
        display: block;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;

      p {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        color: $mainColor;
        margin: 7px 15px;
        text-align: center;

        span {
          font-weight: 500;
        }
      }
    }

    &__buttons {
      width: 100%;
      flex-basis: 25%;
    }

    &__btn {
      margin-bottom: 15px;
      width: 100%;

      a {
        display: block;
        text-align: center;
        color: $mainColor;
        border: 1px solid $mainColor;
        padding: 8px 15px;
        border-radius: 25px;
        text-decoration: none;
        font-size: px-to-rem(12px);
        line-height: px-to-rem(14px);

        &:hover {
          text-decoration: underline;
        }
      }

      &--vip {
        a {
          border-color: $mainRed;
          color: $mainRed;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .dashboard {
    &__item {
      &__grid {
        flex-direction: row;
      }

      &__title {
        margin: 0 30px;
      }

      &__info {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        p {
          margin: 10px;
        }
      }

      &__img {
        margin: 0;
      }
    }
  }
}
