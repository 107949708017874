.add {
  &__wrap {
    padding-top: 110px;
    padding-bottom: 50px;
  }

  &__title {
    font-size: px-to-rem(22px);
    line-height: px-to-rem(33px);
    margin: 0 auto;
    font-weight: 700;
    color: $mainColor;
    margin-bottom: 20px;
    text-align: center;
  }

  &__form {
    width: 100%;
    max-width: 620px;
    margin: 30px auto 0;

    &__delete {
      text-align: center;
      font-size: px-to-rem(16px);
      line-height: px-to-rem(24px);
      color: $mainColor;
      margin: 30px 0;

      span {
        font-weight: 500;
      }
    }

    &__middle,
    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__boards {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      img {
        width: 70px;
        height: auto;
        cursor: pointer;
      }
    }

    &__radio {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 10px 20px;

      label {
        margin-left: 15px;
      }
    }

    &__star {
      color: $mainRed;
      font-size: px-to-rem(18px);
    }

    &__field {
      margin-top: 10px;

      label {
        font-size: px-to-rem(12px);
        line-height: px-to-rem(14px);
        color: $mainColor;
      }

      select {
        margin-top: 10px;
        width: 100%;
        padding: 7px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        color: $mainColor;
        border-radius: 5px;
        border: 1px solid lighten($mainColor, 60%);

        &:focus {
          outline: none;
        }
      }

      textarea {
        margin-top: 10px;
        height: 250px;
        resize: none;
        width: 96%;
        padding: 8px 2% 8px 2%;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(21px);
        color: $mainColor;
        border-radius: 5px;

        &::placeholder {
          color: $mainGrey;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &__text {
      margin-top: 10px;
      width: 96%;
      padding: 8px 2% 8px 2%;
      border-radius: 5px;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(16px);
      color: $mainColor;
      border: 1px solid $mainGrey;

      &::placeholder {
        color: $mainGrey;
      }

      &:focus {
        outline: none;
      }
    }

    &__input {
      border: 1px solid $mainGreen;
      transition-property: border-color;
      transition-duration: 500ms;

      &--empty {
        border: 1px solid $mainGrey;
      }
    }

    &__description {
      font-size: px-to-rem(12px);
      line-height: px-to-rem(18px);
      color: $mainColor;
      margin: 5px 0 15px;

      span {
        color: $mainRed;
      }
    }

    &__addinfo {
      font-size: px-to-rem(12px);
      line-height: px-to-rem(18px);
      text-align: justify;
      color: $mainColor;
      margin: 0;
      margin-top: 10px;

      a {
        color: $black;
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }

      &--first {
        margin-top: 20px;
      }
    }

    &__btn {
      margin: 30px auto 0;
      width: 225px;

      button {
        border-radius: 25px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        background-color: $mainRed;
        color: $white;
        padding: 12px 0;
        width: 100%;
        border: none;
        transition-property: background-color;
        transition-duration: 500ms;
        cursor: pointer;

        &:hover {
          background-color: lighten($mainRed, 10%);
        }

        &:focus {
          outline: none;
        }
      }

      &--disabled {
        button {
          background-color: $mainGrey;
          pointer-events: none;
        }
      }

      &--edit-control {
        button {
          background-color: $mainGrey;
          pointer-events: none;
        }
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-bottom: 15px;
        color: $mainColor;
        border-radius: 25px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        border: 1px solid $mainColor;
        padding: 12px 0;
        width: 100%;
        text-decoration: none;
        text-align: center;
        max-width: 225px;
        background-color: $white;
      }

      button {
        border-radius: 25px;
        font-size: px-to-rem(14px);
        line-height: px-to-rem(16px);
        background-color: $mainRed;
        color: $white;
        padding: 12px 0;
        width: 100%;
        border: none;
        max-width: 225px;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    &__checkbox {
      margin-top: 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      gap: 16px;

      input {
        cursor: pointer;
      }

      label {
        font-size: px-to-rem(12px);
        line-height: px-to-rem(18px);
        color: $mainColor;
        text-align: justify;
        font-weight: normal;
        cursor: pointer;
      }
    }
  }

  &__edit-inform {
    max-width: 580px;
    background-color: $addInfoColor;
    border: 1px solid darken($addInfoColor, 20);
    padding: 15px 20px;
    color: $mainColor;
    margin: 25px auto 0;
    border-radius: 10px;

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(28px);
      text-align: justify;
    }
  }
}

@media (min-width: 576px) {
  .add {
    &__form {
      &__buttons {
        flex-direction: row;
        justify-content: center;

        a {
          margin: 0;
          margin-right: 10px;
        }

        button {
          margin-left: 10px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .add {
    &__form {
      &__middle,
      &__bottom {
        flex-direction: row;
        flex-wrap: wrap;

        &__item {
          flex-basis: 31%;

          &--long {
            flex-basis: 65%;
          }
        }
      }
    }
  }
}
