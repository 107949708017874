@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.woff') format('woff'),
    url('../fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}