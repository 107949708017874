.login {
    &__wrap {
        padding-top: 110px;
        padding-bottom: 50px;
    }

    &__title {
        font-size: px-to-rem(22px);
        line-height: px-to-rem(33px);
        margin: 0 auto;
        font-weight: 700;
        color: $mainColor;
        margin-bottom: 20px;
        text-align: center;
    }

    &__input {
        margin: 0 auto 10px;
        width: 290px;

        input {
            width: 83%;
            padding: 10px 15px 10px 25px;
            border: 1px solid $mainColor;
            border-radius: 25px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            transition-property: border;
            transition-duration: 500ms;

            &:focus {
                outline: none;
            }
        }

        .login__empty {
            border: 1px solid lighten($mainColor, 60%);
        }
    }

    &__forgot {
        margin-top: 15px;
        text-align: center;

        a {
            text-decoration: none;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            color: $mainColor;
            font-weight: 500;
            position: relative;

            &::before {
                content: '';
                width: 4px;
                height: 4px;
                background-color: $mainColor;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -15px;
                border-radius: 50%;
            }

            &:hover {
                color: $mainRed;

                &::before {
                    background-color: $mainRed;
                }
            }
        }
    }

    &__btn {
        margin: 30px auto 0;
        width: 225px;

        button {
            border-radius: 25px;
            font-size: px-to-rem(14px);
            line-height: px-to-rem(16px);
            background-color: $mainRed;
            color: $white;
            padding: 12px 0;
            width: 100%;
            border: none;
            transition-property: background-color;
            transition-duration: 500ms;
            cursor: pointer;

            &:hover {
                background-color: lighten($mainRed, 10%);
            }
        }

        &--disabled {
            button {
                background-color: $mainGrey;
                pointer-events: none;
            }
        }
    }
}