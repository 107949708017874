.table {
  &__title {
    padding-top: 110px;
    padding-bottom: 30px;

    h1 {
      font-size: px-to-rem(22px);
      line-height: px-to-rem(33px);
      text-align: center;
      font-weight: 700;
      color: $mainColor;
      margin: 0;
    }
  }

  &__info {
    text-align: center;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(21px);
    margin-bottom: 30px;

    p {
      margin: 0;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 50px;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto 50px;
    border-radius: 5px 5px 0 0;
    overflow-x: scroll;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(21px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr {
          background-color: lighten($mainColor, 15%);
        }

        th {
          padding: 15px;
          color: $white;
          text-align: center;
          font-weight: 400;

          &:first-child {
            position: sticky;
            left: 0;
            background-color: lighten($mainColor, 15%);
          }
        }
      }

      tbody {
        tr {
          background-color: $white;

          &:nth-of-type(even) {
            border-top: 1px solid lighten($mainColor, 50%);
            border-bottom: 1px solid lighten($mainColor, 50%);
            background-color: lighten($mainColor, 65%);

            td {
              &:first-child {
                background-color: lighten($mainColor, 65%);
              }
            }
          }

          &:last-of-type {
            border-bottom: 2px solid $mainColor;
          }
        }

        td {
          padding: 10px 15px;
          text-align: center;
          height: 80px;

          &:first-child {
            position: sticky;
            left: 0;
            background-color: $white;
          }

          span {
            display: inline-block;
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
          }
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          color: $mainColor;
          font-weight: 500;

          img {
            width: 40px;
            height: auto;
            margin-bottom: 10px;
          }

          span {
            font-size: px-to-rem(12px);
            line-height: px-to-rem(14px);
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .table {
    &__content {
      overflow: auto;
    }
  }
}
