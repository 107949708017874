.footer {
  background-color: $mainColor;

  &__wrap {
    padding: 50px 15px 30px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-btn {
      display: block;
      text-align: center;
      text-decoration: none;
      color: $mainColor;
      font-weight: 500;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(16px);
      letter-spacing: 0.5px;
      border-radius: 25px;
      border: 2px solid $white;
      padding: 10px 15px;
      background-color: $white;
      opacity: 0.9;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__categories {
    margin-top: 24px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    li {
      margin: 10px 0;
    }

    a {
      color: $white;
      text-decoration: none;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(16px);
      font-weight: 500;
      letter-spacing: 0.5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__payment {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin: 15px 0;
      max-height: 20px;
    }
  }

  &__under {
    margin: 30px auto 0;
    max-width: 750px;

    p {
      color: $white;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(18px);
      font-weight: 400;
      text-align: center;
      margin: 0;
    }

    a {
      color: $white;
      letter-spacing: 0.8px;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 1024px) {
  .footer {
    &__wrap {
      padding: 40px 45px;
    }

    &__grid {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    &__payment {
      margin: 0;
      order: 1;
    }

    &__categories {
      order: 2;

      ul {
        flex-direction: row;
      }

      li {
        margin: 0 10px;
      }
    }

    &__contacts {
      order: 3;
    }
  }
}
