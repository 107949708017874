.rules {
    &__wrap {
        padding-top: 110px;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }

    &__title {
        h1 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            margin: 0 auto;
            font-weight: 700;
            color: $mainColor;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    &__text {
        max-width: 600px;
        margin: 0 auto;

        h2 {
            font-size: px-to-rem(22px);
            line-height: px-to-rem(33px);
            font-weight: 700;
            color: $mainColor;
        }

        p {
            font-size: px-to-rem(16px);
            line-height: px-to-rem(24px);
            color: $mainColor;
            margin: 0;
            text-align: justify;
            margin-bottom: 10px;
        }

        span {
            font-weight: 500;
        }

        &__list {
            margin-top: 20px;

            ol {
                margin: 0;
                padding: 0 0 0 15px;
            }

            li {
                font-size: px-to-rem(16px);
                line-height: px-to-rem(24px);
                color: $mainColor;
                text-align: justify;
                margin-bottom: 10px;
            }
        }
    }
}