@charset "UTF-8";

/**
 * Основные стили
 **/

html {
	font-size: 16px;
	color: $black;
}

body {
	font-family: 'Roboto', 'Arial', sans-serif;
	min-width: 320px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	background-color: $bodyBg;
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}

.hidden {
	@include visually-hidden();
}

.blackscreen {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: -10%;
	background-color: rgba(0, 0, 0, .25);
	z-index: 15;

	&--active {
		display: block;
	}
}