.balance {
    &__wrap {
        padding-top: 110px;
        padding-bottom: 50px;
    }

    &__title {
        font-size: px-to-rem(22px);
        line-height: px-to-rem(33px);
        margin: 0 auto;
        font-weight: 700;
        color: $mainColor;
        margin-bottom: 20px;
        text-align: center;
    }

    &__form {
        width: 100%;
        max-width: 400px;
        margin: 30px auto 0;

        &__input {
            input {
                width: 96%;
                padding: 8px 2% 8px 2%;
                border-radius: 5px;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                color: $mainColor;
                border: 1px solid lighten($mainColor, 60%);

                &:focus {
                    outline: none;
                }
            }
        }

        &__addinfo {
            margin-top: 20px;
            margin-left: 10px;

            p {
                margin: 0;
                font-size: px-to-rem(12px);
                line-height: px-to-rem(14px);
                color: $mainColor;
                margin-bottom: 10px;
            }
        }

        &__btn {
            margin: 30px auto 0;
            width: 225px;

            button {
                border-radius: 25px;
                font-size: px-to-rem(14px);
                line-height: px-to-rem(16px);
                background-color: $mainRed;
                color: $white;
                padding: 12px 0;
                width: 100%;
                border: none;
                transition-property: background-color;
                transition-duration: 500ms;
                cursor: pointer;

                &:hover {
                    background-color: lighten($mainRed, 10%);
                }

                &:focus {
                    outline: none;
                }
            }

            &--disabled {
                button {
                    background-color: $mainGrey;
                    pointer-events: none;
                }
            }
        }
    }
}