@mixin clearfix() {

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin min-max($min-width, $max-width, $padding) {
  min-width: $min-width;
  max-width: $max-width;
  padding: 0 $padding;
  box-sizing: border-box;
  margin: 0 auto;
}

@mixin adaptive-video() {
  position: relative;
  padding-bottom: 56.25%;
  /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@mixin visually-hidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

@function px-to-rem($size, $basicSize: 16px) {
  @return $size / $basicSize * 1rem;
}

@mixin dynamicText($minSizePercent, $maxSize, $minSize, $minWidthPx, $maxWidth, $minWidth) {
  font-size: calc(#{$minSizePercent} + (#{$maxSize} - #{$minSize}) * (100vw - #{$minWidthPx}) / (#{$maxWidth} - #{$minWidth}));
}